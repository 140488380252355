import { Select } from 'antd';
import styles from './index.module.scss';
import {
  AirIcon, CourierIcon, RailIcon, SeaIcon, TruckIcon,
} from '../../../../Common/Icon';
import Label from './Label';
import { useContextForm } from '../../Context';

export default function ShippingSelect(): React.ReactNode | null {
  const { formData, setForm } = useContextForm();

  return (
    <Select
      defaultValue="courier"
      className={styles.select}
      popupClassName={styles.options}
      value={formData?.shipper?.shippingType}
      onChange={(value) => {
        if (formData) {
          setForm('shipper', { ...formData.shipper, shippingType: value });
        }
      }}
      options={[
        {
          value: 'Courier',
          label: <Label title="Courier" icon={<CourierIcon />} />,
        },
        {
          value: 'Road Freight (ADR)',
          label: <Label title="Truck" icon={<TruckIcon />} />,
        },
        {
          value: 'Air Freight (IATA)',
          label: <Label title="Air" icon={<AirIcon />} />,
        },
        {
          value: 'Sea Freight (IMDG-IMO)',
          label: <Label title="Sea" icon={<SeaIcon />} />,
        },
        {
          value: 'Rail',
          label: <Label title="Rail" icon={<RailIcon />} />,
        },
      ]}
    />
  );
}
