// @ts-nocheck
import React from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  NonIndexRouteObject,
  IndexRouteObject,
} from 'react-router-dom';
import { paramsToString, routes as menuRoutes } from '../components/Layout/Menu';
import PrivateRoute from './PrivateRoute';
import { Role } from '../enums/user';
import Form from '../pages/Form';
import Tracking from '../pages/Tracking';

export interface AnyObject {
  [key: string | number]: AnyObject | string | number | boolean | null | any;
}

export function generateDefaultUrl(user?: User | null) {
  return `${paramsToString(
    user,
    user && user?.role === Role.ADMIN ? undefined : getRouteParams('/', user),
  )}`;
}

export interface RouteElements {
  [role: string]: React.ReactNode | null;
}

interface RouteObjectExtension {
  elements?: RouteElements;
  params?: ((user) => string) | AnyObject;
}

interface MyIndexRouteObject extends IndexRouteObject, RouteObjectExtension {
}

interface MyNonIndexRouteObject extends NonIndexRouteObject, RouteObjectExtension {
}

export type RouteObject = MyIndexRouteObject | MyNonIndexRouteObject;
export type RouteRoles = (string | ((user) => boolean))[];

export interface Route {
  route?: RouteObject;
  roles?: RouteRoles;
  name?: string | ((user) => string);
  icon?: React.ReactNode | ((user) => React.ReactNode);
  parent?: Route;
  hidden?: boolean;
  onClick?: () => void;
  children?: Route[];
  outlet?: true;
  roleNeeded?: 'user' | 'manager' | 'admin';
  privateRoute?: boolean;
  disabled?: string | ((user) => boolean);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function addChildrenRoutes(routes: Route[]): Route[] {
  const list: Route[] = [];

  const recursiveUpdate = (item: Route, parent?: Route): Route => {
    if (parent) {
      // eslint-disable-next-line no-param-reassign
      item.parent = parent;

      if (parent?.route?.children && item.route) {
        parent.route.children.push(item.route);
      }

      if (!item.outlet && item.route) {
        list.push(item);

        if (parent.route) {
          const childList = (parent.route?.children || []).filter((child) => child !== item.route);

          if (childList.length > 0) {
            Object.assign(parent.route, {
              children: childList,
            });
          } else {
            // eslint-disable-next-line no-param-reassign
            delete parent.route.children;
          }
        }

        Object.assign(item.route, {
          path: `${parent.route?.path}/${item.route?.path}`.replace(/\/+/, '/').replace(/^\/+/, ''),
        });
      }
    }

    if (item.route) {
      const { route, children, privateRoute } = item;

      if (privateRoute && (route?.element || route?.elements)) {
        route.element = <PrivateRoute element={route?.element} elements={route?.elements} item={item} />;
      }

      if (children) {
        // eslint-disable-next-line no-param-reassign
        route.children = [];

        for (const childRoute of children) recursiveUpdate(childRoute, item);
      }
    } else if (item.children) {
      for (const child of item.children.filter(({ route }) => route)) list.push(recursiveUpdate(child));
    }

    return item;
  };

  return [...routes.map((route) => recursiveUpdate(route)), ...list].filter(({ route }) => route);
}

export const routes: Route[] = addChildrenRoutes([
  {
    // privateRoute: true,
    name: 'Home',
    privateRoute: true,
    route: {
      path: '/',
      element: <Form />,
    },
    children: [
      {
        name: 'Tracking',
        route: {
          path: 'tracking',
          element: <Tracking />,
        },
        children: [
          {
            name: 'Tracking number',
            route: {
              path: ':trakingnumber',
              element: <Tracking />,
            },
          },
        ],
      },
      ...menuRoutes,
    ],
  },
]);

const router = createBrowserRouter([
  ...routes.map(({ route }) => route as RouteObject),
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

export function getRouteParams(path: string, user?): AnyObject {
  let params: ((user) => string) | AnyObject = {};

  routes.find(({ route }) => {
    if (route && route.path && route.path === path) {
      if (route.params) {
        params = route.params;
      }

      return true;
    }

    return false;
  });

  if (typeof params === 'function') {
    if (user) {
      return params(user);
    }

    return {};
  }

  return params;
}

export default function Routes(): React.ReactNode {
  return <RouterProvider router={router} />;
}
