import {
  Row,
  Col,
  Typography,
  Form,
  Button,
  Flex,
  Checkbox,
  Input as AntdInput,
} from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import RadioBtns from '../../Components/RadioBtns';
import Input from '../../Components/Float/Input';
import Time from '../../Components/Float/Time';
import Date from '../../Components/Float/Date';
import CustomSwitch from '../../Components/CustomSwitch';
import { useContextForm } from '../../Context';
import {
  createRulesForAntd,
  validationShipping,
} from '../../../../../utils/validations';
import CustomCheckboxSwitch from '../../Components/CustomCheckbox';
import SelectPlace from '../../Components/Float/SelectPlace';
import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationShipping);

export default function Shipping(): React.ReactNode | null {
  const {
    setCurrent, current, setForm, formData,
  } = useContextForm();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isValid, setValid] = useState(false);

  const pickupFromWatch = Form.useWatch('pickupFrom', form) || '';
  const pickupToWatch = Form.useWatch('pickupTo', form) || '';

  useEffect(() => {
    // if after time change range less than 2 hours - clear pickupTo time.
    if ((pickupFromWatch && pickupToWatch)
      && (dayjs(pickupToWatch).diff(dayjs(pickupFromWatch), 'hour') < 2)) {
      form.setFieldValue('pickupTo', '');
    }
  }, [pickupFromWatch, pickupToWatch]);

  useEffect(() => {
    if (formData?.shipper) {
      form.setFieldsValue({
        ...formData.shipper,
        pickupDate: dayjs(formData.shipper.pickupDateFrom || dayjs()).utc(),
        pickupFrom: formData?.shipper?.pickupDateFrom ? dayjs(formData.shipper.pickupDateFrom || dayjs()).utc() : null,
        pickupTo: formData?.shipper?.pickupDateTo ? dayjs(formData.shipper.pickupDateTo || dayjs()).utc() : null,
        // shippingType: formData?.modeOfTransport,
      });
      setValid(() => true);
    } else {
      form.setFieldsValue({
        urgent: false,
        shippingType: 'Courier',
        isPickupAddressDifferent: false,
      });
    }
  }, [formData?.shipper]);

  const onFieldsChange = (currentField: any, allFields: any[]) => {
    if (allFields.some((field) => field.errors.length)) {
      setValid(() => false);
    } else {
      setValid(() => true);
    }

    if (
      currentField?.[0]?.name?.[0] === 'isPickupAddressDifferent'
      && !currentField?.[0]?.value
    ) {
      form.validateFields();
    }
  };

  const shippingPlaces = {
    country: 'country',
    postalCode: 'postalCode',
    city: 'city',
    state: 'state',
    address: 'address1',
    countryCode: 'countryCode',
    stateCode: 'stateCode',
  };
  const pickupPlaces = {
    country: 'pickupCountry',
    postalCode: 'pickupPostalCode',
    city: 'pickupCity',
    state: 'pickupState',
    address: 'pickupAddress1',
    countryCode: 'pickupCountryCode',
    stateCode: 'pickupStateCode',
  };

  /** Add prefilled values for form */
  // TODO remove 'prefilled' feature
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('prefilled') && !formData?.shipper) {
      const newValues = {
        shippingType: 'Courier',
        countryCode: 'UA',
        stateCode: "Cherkas'ka oblast",
        contactName: 'Company-contact',
        company: 'Company-name',
        phone: '12345678987',
        email: 'company-mail@gda.com',
        country: 'Ukraine',
        address1: 'Blahovisna Street',
        address2: '',
        postalCode: '18000',
        city: 'Cherkasy',
        state: "Cherkas'ka oblast",
        tin: '124',
        registerNumber: '421',
        pickupDate: dayjs('2024-07-11T21:00:00.000Z'),
        pickupFrom: dayjs('2024-07-11T09:15:00.000Z'),
        pickupTo: dayjs('2024-07-11T14:30:00.000Z'),
        urgent: true,
        isPickupAddressDifferent: false,
      };

      form.resetFields();
      setTimeout(() => form.setFieldsValue(newValues), 150);
      setValid(true);
    }
  }, [searchParams.get('prefilled')]);

  return (
    <div className={styles.wrapper}>
      <Form
        onFieldsChange={onFieldsChange}
        form={form}
        autoComplete="off"
        className={styles.form}
      >
        <Typography.Title level={2}>Shipping type</Typography.Title>
        <RadioBtns />
        <Typography.Title level={2}>Shipper details</Typography.Title>
        <Typography.Paragraph className={styles.required}>
          <span className="text-danger">*</span>
          – fields are required
        </Typography.Paragraph>

        <Row
          gutter={[
            {
              xs: 16,
              sm: 16,
              md: 16,
              lg: 24,
            },
            28,
          ]}
        >
          <Form.Item hidden name="countryCode">
            <AntdInput />
          </Form.Item>
          <Form.Item hidden name="stateCode">
            <AntdInput />
          </Form.Item>

          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="contactName"
              rules={[
                { required: true, message: 'Please enter a contact name.' },
              ]}
            >
              <Input required label="Contact name" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="company"
              rules={[{ required: true, message: 'Please enter a company.' }]}
            >
              <Input required label="Company" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="phone" rules={[validationRules]}>
              <Input required label="Mobile number" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="email" rules={[validationRules]}>
              <Input required label="Email" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="country"
              rules={[{ required: true, message: 'Please enter a country.' }]}
            >
              <SelectPlace
                label="Country"
                value={values?.country}
                type={['country']}
                form={form}
                fields={shippingPlaces}
                name="country"
                required
                onSelect={() => {
                  ['address1', 'address2', 'postalCode', 'city', 'state'].forEach((key) => {
                    form.setFieldValue(key, undefined);
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="address1"
              rules={[{ required: true, message: 'Please enter a address.' }]}
            >
              <SelectPlace
                label="Address"
                value={values?.address1}
                type={['address']}
                fields={shippingPlaces}
                form={form}
                name="address1"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="address2">
              <Input label="Address 2" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="postalCode"
              rules={[
                { required: true, message: 'Please enter a postal code.' },
              ]}
            >
              <SelectPlace
                label="Postal code"
                value={values?.postalCode}
                type={['postal_code']}
                fields={shippingPlaces}
                form={form}
                name="postalCode"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="city"
              rules={[
                { required: true, message: 'Please enter a city / town.' },
              ]}
            >
              <SelectPlace
                label="City / Town"
                value={values?.city}
                type={['locality']}
                fields={shippingPlaces}
                form={form}
                name="city"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item
              name="state"
              rules={[
                {
                  required: true,
                  message: 'Please enter a province /region / state.',
                },
              ]}
            >
              <SelectPlace
                label="Province / Region / State"
                value={values?.state}
                type={['administrative_area_level_1']}
                form={form}
                fields={shippingPlaces}
                name="state"
                required
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="tin">
              <Input label="TIN number" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="registerNumber">
              <Input
                label="Register number"
                hint="EORI (for European countries only) EIN, GLN or special company register number"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <Form.Item name="reference">
              <Input
                label="Reference"
                hint="Will be specified on an additional invoice line under the reference or purchase order number"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} xs={24}>
            <Typography.Title level={2}>Pickup details</Typography.Title>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <Date label="Pickup date" name="pickupDate" form={form} />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Time label="From" name="pickupFrom" form={form} timeType="start" />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Time label="To" name="pickupTo" form={form} timeType="end" dependencyTime={pickupFromWatch} />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item name="urgent">
              <CustomSwitch title="Urgent" name="urgent" form={form} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item valuePropName="checked" name="isPickupAddressDifferent">
              <Checkbox className="invisible">
                <CustomCheckboxSwitch
                  title="The Pickup address differ from the Shipper's address"
                  checked={values?.isPickupAddressDifferent}
                />
              </Checkbox>
            </Form.Item>
          </Col>

          {values?.isPickupAddressDifferent && (
            <>
              <Form.Item hidden name="pickupCountryCode">
                <AntdInput />
              </Form.Item>
              <Form.Item hidden name="pickupStateCode">
                <AntdInput />
              </Form.Item>

              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupContactName"
                  rules={[
                    { required: true, message: 'Please enter a contact name.' },
                  ]}
                >
                  <Input required label="Contact name" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupCompany"
                  rules={[
                    { required: true, message: 'Please enter a company.' },
                  ]}
                >
                  <Input required label="Company" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="pickupPhone" rules={[validationRules]}>
                  <Input required label="Mobile number" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="pickupEmail" rules={[validationRules]}>
                  <Input required label="Email" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupCountry"
                  rules={[
                    { required: true, message: 'Please enter a country.' },
                  ]}
                >
                  <SelectPlace
                    label="Country"
                    value={values?.pickupCountry}
                    type={['country']}
                    form={form}
                    fields={pickupPlaces}
                    name="pickupCountry"
                    required
                    onSelect={() => {
                      ['pickupAddress1', 'pickupAddress2', 'pickupPostalCode', 'pickupCity', 'pickupState']
                        .forEach((key) => {
                          form.setFieldValue(key, undefined);
                        });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupAddress1"
                  rules={[
                    { required: true, message: 'Please enter a address.' },
                  ]}
                >
                  <SelectPlace
                    label="Address"
                    value={values?.pickupAddress1}
                    type={['address']}
                    fields={pickupPlaces}
                    form={form}
                    name="pickupAddress1"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="pickupAddress2">
                  <Input label="Address 2" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupPostalCode"
                  rules={[
                    { required: true, message: 'Please enter a postal code.' },
                  ]}
                >
                  <SelectPlace
                    label="Postal code"
                    value={values?.pickupPostalCode}
                    type={['postal_code']}
                    fields={pickupPlaces}
                    form={form}
                    name="pickupPostalCode"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupCity"
                  rules={[
                    { required: true, message: 'Please enter a city / town.' },
                  ]}
                >
                  <SelectPlace
                    label="City / Town"
                    value={values?.pickupCity}
                    type={['locality']}
                    fields={pickupPlaces}
                    form={form}
                    name="pickupCity"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item
                  name="pickupState"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a province /region / state.',
                    },
                  ]}
                >
                  <SelectPlace
                    label="Province / Region / State"
                    value={values?.pickupState}
                    type={['administrative_area_level_1']}
                    form={form}
                    fields={pickupPlaces}
                    name="pickupState"
                    required
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} xs={24}>
                <Form.Item name="pickupRegisterNumber">
                  <Input
                    label="Register number"
                    hint="EORI (for European countries only) EIN, GLN or special company register number"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Flex justify="flex-end">
              <Button
                size="large"
                disabled={!isValid}
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      const {
                        pickupDate, pickupFrom, pickupTo, ...data
                      } = form.getFieldsValue();

                      if (pickupDate && pickupFrom && pickupTo) {
                        const pickupDateFrom = dayjs(
                          `${pickupDate.format(
                            'YYYY-MM-DD',
                          )}T${pickupFrom.format('HH:mm:ss')}.000Z`,
                        ).toISOString();
                        const pickupDateTo = dayjs(
                          `${pickupDate.format('YYYY-MM-DD')}T${pickupTo.format(
                            'HH:mm:ss',
                          )}.000Z`,
                        ).toISOString();

                        setForm('shipper', {
                          ...data,
                          pickupDateFrom,
                          pickupDateTo,
                        });
                      } else {
                        setForm('shipper', { ...data });
                      }
                      setCurrent(current + 1);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                CONTINUE TO IMPORTER
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
