import {
  Typography,
  Form,
  Button,
  Flex,
  Collapse,
  Row,
  Col,
  Checkbox,
} from 'antd';
import { useEffect, useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import GoodsItem from '../../Components/GoodsItem';
import Select from '../../Components/Float/Select';
import Upload from '../../Components/Float/Upload';
import CustomCheckboxSwitch from '../../Components/CustomCheckbox';
import Addon from '../../Components/Float/Addon';
import { useContextForm } from '../../Context';
import styles from './index.module.scss';
import {
  Order, TrackingGoods,
  useCurrenciesGet,
  useDeliveryTermsGet,
  useMassUnitGet,
  useTemperatureModesGet,
} from '../../../../../hooks/api/order';
import { useMessageError } from '../../../../../hooks/common';

const calcGoodsItem = (item: TrackingGoods) => {
  /** if measure unit is 'L' */
  if (item.massUnit === 'L' && item.density) {
    if (item.innerPackaging) {
      return (
        (((item.innerPackagingQuantity || 0) * (item.innerPackagingNetUnitaryQuantity || 0)) * item.density)
        * (item.quantity || 1)
      );
    }

    /** if not inner packaging and mass unit 'L' */
    return ((item.net || 0) * item.density) * (item.quantity || 1);
  }

  /** If item measure unit is KG */
  return item.innerPackaging
    ? (
      ((item.innerPackagingQuantity || 0)
        * (item.innerPackagingNetUnitaryQuantity || 0))
      * (item.quantity || 1)
    )
    : (item.net || 0) * (item.quantity || 1);
};

export default function Goods(): React.ReactNode | null {
  const {
    setCurrent, current, formData, setFormData, currency, setCurrency,
  } = useContextForm();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isValid, setValid] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[]>('0');
  const deliveryTermsGet = useDeliveryTermsGet();
  const massUnitGet = useMassUnitGet();
  const currenciesGet = useCurrenciesGet();
  const temperatureModesGet = useTemperatureModesGet();

  useEffect(() => {
    form.setFieldValue('insuranceCurrency', currency);
  }, [currency]);

  useEffect(() => {
    if (values?.insuranceCurrency) {
      setCurrency(values.insuranceCurrency);
    }
  }, [values?.insuranceCurrency]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (allFields.some((field) => field.errors.length)) {
      setValid(() => false);
    } else {
      setValid(() => true);
    }
  };

  const defaultValues = {
    currency: currency || currenciesGet.data?.[2],
    massUnit: massUnitGet?.data?.[0],
    volume: 0,
  };

  useEffect(() => {
    if (formData?.goods) {
      form.setFieldsValue(formData);

      setValid(() => true);
    } else {
      form.setFieldsValue({
        insurance: false,
        insuranceCurrency: currenciesGet.data?.[2],
        goods: [defaultValues],
        additionalDocuments: [{}],
      });
    }
  }, [massUnitGet?.data, currenciesGet?.data]);

  useEffect(() => {
    deliveryTermsGet.fetch();
    massUnitGet.fetch();
    currenciesGet.fetch();
    temperatureModesGet.fetch();
  }, []);

  const getHeader = (key: number, remove: (i: number) => void) => (
    <div className={styles.header}>
      <div className={styles.headerKey}>{`#${key + 1}`}</div>
      {(!activeKey?.[0]
        || (activeKey?.[0] && Number.parseInt(activeKey[0], 10) !== key)) && (
        <>
          <div className={styles.count}>
            {values.goods?.[key]?.qty && `${values.goods?.[key]?.qty}x`}
          </div>
          <div className={styles.headerName}>
            {values.goods?.[key]?.packaging}
          </div>
          <div className={styles.descriptions}>
            {values.goods?.[key]?.descriptionGoods}
          </div>
          <div className={styles.gross}>
            {values.goods?.[key]?.net && `${values.goods?.[key]?.net} ${values.goods?.[key]?.massUnit || 'kg'}`}
            {/* {values.goods?.[key]?.gross && `${values.goods?.[key]?.gross}kg`} */}
          </div>
        </>
      )}
      <div className={styles.remove}>
        <DeleteFilled onClick={() => remove(key)} />
      </div>
    </div>
  );

  useEffect(() => {
    if (values?.insurance) {
      const insurance = values?.goods?.reduce((sum: number, curr: any) => {
        if (!Number(curr?.value)) {
          return 0 + sum;
        }

        return ((Number(curr?.quantity) || 1) * Number(curr?.value) || 0) + sum;
      }, 0);

      if (insurance !== 0) {
        form.setFieldValue('insuranceValue', insurance);
      } else {
        form.setFieldValue('insuranceValue', null);
      }
    }
  }, [values?.insurance]);

  useMessageError([massUnitGet, deliveryTermsGet, currenciesGet]);

  const handleStep = (direction: 'next' | 'prev') => {
    if (direction === 'prev') {
      const { goods, ...others } = form.getFieldsValue();
      const { insuranceCurrency, insuranceValue, ...formDataValues } = formData as Order;

      setFormData({
        ...formDataValues,
        goods,
        ...others,
      });
      setCurrent(current - 1);
    } else {
      form
        .validateFields()
        .then(() => {
          const { goods, ...others } = form.getFieldsValue();
          const { insuranceCurrency, insuranceValue, ...formDataValues } = formData as Order;

          setFormData({
            ...formDataValues,
            goods,
            ...others,
          });
          setCurrent(current + 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const calcNetTotal = (list: TrackingGoods[]): React.ReactNode => (
    Number.parseFloat((list || []).reduce((
      accumulator,
      value,
    ) => accumulator + calcGoodsItem(value), 0).toFixed(3))
  );

  /** Add prefilled values for form */
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('prefilled') && !formData?.goods
      && (massUnitGet?.data && currenciesGet?.data) // Trigger set data after requests finish
    ) {
      const newValues = {
        goods: [
          {
            currency: 'CHF',
            massUnit: 'kg',
            volume: 0.12,
            hsCode: '76011010',
            packaging: 5,
            description: 'description-of-goods',
            quantity: 4,
            net: '30',
            gross: 1,
            length: 100,
            width: 40,
            height: 30,
            permit: 'free',
            dangerousGoods: true,
            unNumber: '1263 PAINT, I, F1 (class 3), 3',
            dangerousGood: 647,
            packagingGroup: 'I',
            innerPackaging: true,
            innerPackagingQuantity: 20,
            innerPackagingType: 4,
            innerPackagingNetUnitaryQuantity: 0.5,
            value: '100',
            specialTemperatureMode: true,
            temperatureMode: 'FROBO79 (Frozen Box UN 1845 -79 °C)',
            netWeightOfDryIce: 10,
          },
        ],
        deliveryTerms: 'DAP (Delivered at Place)',
        insurance: false,
        additionalDocuments: [
          {},
        ],
      };

      form.resetFields();
      setTimeout(() => (form.setFieldsValue(newValues)), 400);
      setValid(true);
    }
  }, [searchParams.get('prefilled'), massUnitGet?.data, currenciesGet?.data]);

  return (
    <div className={styles.wrapper}>
      <Form
        onFieldsChange={onFieldsChange}
        form={form}
        autoComplete="off"
        className={styles.form}
      >
        <Typography.Title level={2}>List of goods</Typography.Title>
        <Typography.Paragraph className={styles.required}>
          <span className="text-danger">*</span>
          – fields are required
        </Typography.Paragraph>

        <Form.List name="goods">
          {(fields, { add, remove }) => (
            <>
              <Collapse
                onChange={(key) => setActiveKey(key)}
                defaultActiveKey={[0]}
                activeKey={activeKey}
                accordion
                className={styles.collapse}
              >
                {fields.map(({ key, name, ...restField }) => (
                  <Collapse.Panel
                    header={getHeader(name, remove)}
                    key={key}
                    className={styles.panel}
                  >
                    <GoodsItem
                      massUnit={massUnitGet?.data}
                      currencies={currenciesGet?.data}
                      temperatureModes={temperatureModesGet?.data}
                      key={key}
                      name={name}
                      restField={restField}
                      form={form}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
              <Flex className={styles.btnWrap} justify="flex-end">
                <Form.Item>
                  <Button
                    disabled={!isValid && fields.length > 0}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          add(defaultValues);
                          setActiveKey([values.goods?.length.toString()]);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    ADD ITEM
                  </Button>
                </Form.Item>
              </Flex>
            </>
          )}
        </Form.List>
        <div className={styles.review}>
          <div>
            <span>
              {values?.goods?.length || 0}
              {' '}
            </span>
            unit(s)
          </div>
          <div>
            <div className={styles.reviewItem}>
              Net (total):
              <span>
                {/* {`${Number.parseFloat(values?.goods?.reduce((sum: number, curr: TrackingGoods) => {
                  if (!Number(curr?.net)) {
                    return 0 + sum;
                  }

                  return (
                    ((Number(curr?.quantity) || 1) * Number(curr?.net) || 0)
                    + sum
                  );
                }, 0).toFixed(3))} kg`} */}
                {calcNetTotal(values?.goods || [])}
                {' kg'}
              </span>
            </div>
            <div className={styles.reviewItem}>
              Gross (total):
              <span>
                {`${values?.goods?.reduce((sum: number, curr: any) => {
                  if (!Number(curr?.gross)) {
                    return 0 + sum;
                  }

                  return (
                    ((Number(curr?.quantity) || 1) * Number(curr?.gross) || 0)
                    + sum
                  );
                }, 0)} kg`}
              </span>
            </div>
            <div className={styles.reviewItem}>
              Volume:
              <span>
                {`${Number.parseFloat(values?.goods?.reduce((sum: number, curr: any) => {
                  if (curr?.height && curr?.length && curr?.width) {
                    const volume = (curr.height * curr.length * curr.width) / 1000000;

                    return volume * (Number(curr?.quantity) || 1) + sum;
                  }

                  return sum;
                }, 0).toFixed(3))} m3`}
              </span>
            </div>
          </div>
        </div>
        <Row
          gutter={[
            {
              xs: 16,
              sm: 16,
              md: 16,
              lg: 24,
            },
            28,
          ]}
        >
          <Col sm={24} md={12} xs={24}>
            <Row
              gutter={[
                {
                  xs: 16,
                  sm: 16,
                  md: 16,
                  lg: 24,
                },
                28,
              ]}
            >
              <Col sm={24} md={24} xs={24}>
                <Typography.Title level={5} className={styles.title}>
                  Responsibility
                </Typography.Title>
              </Col>

              <Col sm={24} md={24} xs={24}>
                <Form.Item name="deliveryTerms" className={styles.offset}>
                  <Select
                    value={values?.deliveryTerms}
                    label="Delivery Terms"
                    options={
                      deliveryTermsGet?.data?.map((item) => ({
                        value: item,
                        label: item,
                      })) || []
                    }
                  />
                </Form.Item>

                <Form.Item
                  valuePropName="checked"
                  name="insurance"
                  className={styles.offset}
                >
                  <Checkbox className="invisible">
                    <CustomCheckboxSwitch
                      title="Transport Insurance"
                      checked={values?.insurance}
                    />
                  </Checkbox>
                </Form.Item>

                {values?.insurance ? (
                  <Form.Item name="insuranceValue" className={styles.offset}>
                    <Addon
                      addonName="insuranceCurrency"
                      label="Insurance value"
                      value={values?.insuranceValue}
                      options={
                        currenciesGet?.data?.map((item) => ({
                          value: item,
                          label: item,
                        })) || []
                      }
                    />
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
          </Col>

          <Col sm={24} md={12} xs={24}>
            <Row
              gutter={[
                {
                  xs: 16,
                  sm: 16,
                  md: 16,
                  lg: 24,
                },
                28,
              ]}
            >
              <Col sm={24} md={24} xs={24}>
                <Typography.Title level={5} className={styles.title}>
                  Documents
                </Typography.Title>
              </Col>
              <Col sm={24} md={24} xs={24}>
                <div className={styles.offset}>
                  <Upload
                    name="proformaInvoice"
                    form={form}
                    label="Upload Proforma invoice"
                  />
                </div>
                <Form.List name="additionalDocuments">
                  {(fieldsFile, { add, remove }) => fieldsFile.map(({ key, name }) => (
                    <div key={key} className={styles.offset}>
                      <Upload
                        key={key}
                        nestedName="additionalDocuments"
                        index={name}
                        name="file"
                        add={add}
                        remove={remove}
                        form={form}
                        label="Upload Additional document"
                      />
                    </div>
                  ))}
                </Form.List>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Flex justify="flex-end" gap={8}>
              <Button
                size="large"
                type="default"
                onClick={() => {
                  handleStep('prev');
                }}
              >
                BACK
              </Button>
              <Button
                size="large"
                disabled={!isValid || !values.goods?.length}
                type="primary"
                onClick={() => {
                  handleStep('next');
                }}
              >
                CONTINUE TO OVERVIEW
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
