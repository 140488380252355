import { useEffect } from 'react';
import {
  Upload as AntdUpload, App, Button, Form, FormInstance, UploadProps,
} from 'antd';
import clsx from 'clsx';
import { DeleteFilled } from '@ant-design/icons';
import { useUploadFile } from '../../../../../../hooks/file';
import { getMessageInError } from '../../../../../../hooks/fetch';
import styles from './index.module.scss';

interface UploadPropss {
  label: string;
  form: FormInstance
  name: string
  nestedName?: string
  index?: number
  remove?: ((i: number) => void) | undefined
  add?: ((defaultValue?: any, insertIndex?: number | undefined) => void) | undefined
}

function Upload({
  label, form, name, nestedName, index, remove, add,
}: UploadPropss) {
  const { message } = App.useApp();
  const uploader = useUploadFile();
  const values = Form.useWatch([], form);
  const isIndex = (index === 0 || index);
  const value = (nestedName && isIndex) ? values?.[nestedName]?.[index]?.[name]?.name : values?.[name]?.name;
  const fields = form.getFieldValue(nestedName);

  useEffect(() => {
    if (uploader.data) {
      if (nestedName && isIndex) {
        fields[index][name] = { id: uploader?.data?.id, name: uploader?.data?.originalName };
        form.setFieldsValue({ [nestedName]: fields });
        if (add) {
          add();
        }

        return;
      }

      form.setFieldValue(name, { id: uploader?.data?.id, name: uploader?.data?.originalName });
    }
  }, [uploader.data]);

  useEffect(() => {
    if (uploader.error) {
      message.error(getMessageInError(uploader.error));
      uploader.clearError();
    }
  }, [uploader.error]);

  const props: UploadProps = {
    beforeUpload: (file) => {
      if (!['application/pdf', 'application/msword'].includes(file.type)) {
        message.error('You can only upload PDF/DOC file!');

        return false;
      }

      const formData = new FormData();

      formData.append('file', file as Blob);
      uploader.fetch(formData);

      return false;
    },
  };

  return (
    <div className={styles.wrapper}>
      <span className={clsx(styles.label, value ? styles.isValue : null)}>
        {label}
      </span>

      <div className={styles.name}>
        {value && (
        <>
          <span title={value} className={clsx(styles.value, 'clip')}>{value}</span>
          <DeleteFilled onClick={() => {
            if (nestedName && isIndex) {
              fields[index][name] = null;
              form.setFieldsValue({ [nestedName]: fields });

              if (remove) {
                remove(index);
              }

              return;
            }
            form.setFieldValue(name, null);
          }}
          />
        </>
        )}
      </div>

      <Form.Item name={isIndex ? [index, name] : name} valuePropName="file">
        <AntdUpload {...props}>
          <Button loading={uploader.loading}>CHOOSE</Button>
        </AntdUpload>
      </Form.Item>
    </div>
  );
}

Upload.defaultProps = {
  nestedName: false,
  index: undefined,
  remove: undefined,
  add: undefined,
};

export default Upload;
