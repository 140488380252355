import {
  Row, Col, Form, FormInstance, Checkbox, Typography,
} from 'antd';
import { useEffect, useMemo } from 'react';
import Input from '../Float/Input';
import Addon from '../Float/Addon';
import FloatNumber from '../Float/FloatNumber';
import CustomCheckboxSwitch from '../CustomCheckbox';
import Select from '../Float/Select';
import Upload from '../Float/Upload';
import { useContextForm } from '../../Context';
import SelectTags from '../Float/SelectTags/index';
import Date from '../Float/Date';
import styles from './index.module.scss';

interface GoodItemProps {
  name: number;
  restField: {
    fieldKey?: number | undefined;
  };
  form: FormInstance;
  massUnit: string[] | undefined;
  currencies: string[] | undefined;
  temperatureModes: string[] | undefined;
}

export default function GoodsItem({
  name,
  restField,
  form,
  massUnit,
  currencies,
  temperatureModes,
}: GoodItemProps): React.ReactNode | null {
  const {
    packages, UNnumber, innerPackage, currency, setCurrency,
  } = useContextForm();
  const formValues = Form.useWatch([], form);
  const values = formValues?.goods?.[name];

  const getVolume = useMemo(() => {
    if (values?.height && values?.length && values?.width) {
      const fields = form.getFieldValue('goods');
      const volume = (values.height * values.length * values.width) / 1000000;
      const rouded = Math.round(volume * 1000) / 1000;

      fields[name].volume = rouded || volume;
      setTimeout(() => form.setFieldsValue({ goods: fields }), 50);

      if (rouded) {
        return rouded;
      }

      return volume;
    }

    return undefined;
  }, [values?.height, values?.length, values?.width, values?.quantity]);

  useEffect(() => {
    if (values?.unNumber) {
      const fields = form.getFieldValue('goods');
      const dangerousGood = UNnumber
        // eslint-disable max-len
        ?.find(
          (item) => values.unNumber
            === `${item.unNumber} ${item.name}, ${item.packingGroup}, ${item.classificationCode}, ${item.labels}`,
        );

      if (dangerousGood) {
        fields[name].dangerousGood = dangerousGood.dangerousGoodId;
        fields[name].packagingGroup = dangerousGood.packingGroup;
        form.setFieldsValue({ goods: fields });
      }
    }
  }, [values?.unNumber]);

  useEffect(() => {
    if (values?.currency) {
      setCurrency(values?.currency);
      const fields = form.getFieldValue('goods');

      fields.forEach((_: any, i: number) => {
        fields[i].currency = values?.currency;
      });
      form.setFieldsValue({ goods: fields });
    }
  }, [values?.currency]);

  useEffect(() => {
    if (currency) {
      const fields = form.getFieldValue('goods');

      fields.forEach((_: any, i: number) => {
        fields[i].currency = currency;
      });
      form.setFieldsValue({ goods: fields });
    }
  }, [currency]);

  return (
    <div className={styles.wrapper}>
      <Row
        gutter={[
          {
            xs: 16,
            sm: 16,
            md: 16,
            lg: 24,
          },
          28,
        ]}
      >
        <Col sm={24} md={12} xs={24}>
          <Form.Item
            valuePropName="checked"
            name={[name, 'dangerousGoods']}
            {...restField}
          >
            <Checkbox className="invisible">
              <CustomCheckboxSwitch
                title="Dangerous Goods"
                checked={values?.dangerousGoods}
              />
            </Checkbox>
          </Form.Item>
        </Col>
        <Col sm={24} md={12} xs={24}>
          <Form.Item
            valuePropName="checked"
            name={[name, 'innerPackaging']}
            {...restField}
          >
            <Checkbox className="invisible">
              <CustomCheckboxSwitch
                title="Inner packaging"
                checked={values?.innerPackaging}
              />
            </Checkbox>
          </Form.Item>
        </Col>
        {values?.dangerousGoods && (
          <>
            <Col sm={24} md={12} xs={24}>
              <Form.Item name={[name, 'unNumber']} {...restField}>
                <Select
                  value={values?.unNumber}
                  label="UN number or goods name"
                  search
                  /* eslint-disable max-len */
                  options={
                    UNnumber?.map((item) => ({
                      value: `${item.unNumber} ${item.name}, ${item.packingGroup}, ${item.classificationCode}, ${item.labels}`,
                      label: `${item.unNumber} ${item.name}, ${item.packingGroup}, ${item.classificationCode}, ${item.labels}`,
                      key: `${item.unNumber}${item.name}${item.dangerousGoodId}${item.packingGroup}`,
                    })) || []
                  }
                />
              </Form.Item>
            </Col>
            <Form.Item hidden name={[name, 'dangerousGood']} {...restField}>
              <Input label="dangerousGood" />
            </Form.Item>
            <Col sm={24} md={12} xs={24}>
              <Form.Item name={[name, 'packagingGroup']} {...restField}>
                <Input disabled label="Packing group" />
              </Form.Item>
            </Col>
          </>
        )}
        <Col sm={24} md={12} xs={24}>
          <Form.Item name={[name, 'hsCode']} {...restField}>
            <SelectTags
              form={form}
              name={name}
              value={values?.hsCode}
              label="HS code or goods name"
              search
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={12} xs={24}>
          <Form.Item name={[name, 'packaging']} {...restField}>
            <Select
              value={values?.packaging}
              label="Packaging"
              search
              options={
                packages?.map((item) => ({
                  value: item.packageId,
                  label: item.name,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={24} xs={24}>
          <Form.Item
            name={[name, 'description']}
            rules={[
              {
                required: true,
                message: 'Please enter a description of goods.',
              },
            ]}
            {...restField}
          >
            <Input label="Description of goods" required />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'quantity']} {...restField}>
            <FloatNumber
              label="Qty."
              value={values?.quantity}
              max={999}
              controls
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'net']} {...restField}>
            <Addon
              label="Net"
              addonName="massUnit"
              formName={name}
              options={
                massUnit?.map((item) => ({ value: item, label: item })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'gross']} {...restField}>
            <FloatNumber label="Gross" value={values?.gross} addon="kg" />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'value']} {...restField}>
            <Addon
              addonName="currency"
              label="Value"
              formName={name}
              options={
                currencies?.map((item) => ({ value: item, label: item })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'length']} {...restField}>
            <FloatNumber label="Length" value={values?.length} addon="cm" />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'width']} {...restField}>
            <FloatNumber label="Width" value={values?.width} addon="cm" />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'height']} {...restField}>
            <FloatNumber label="Height" value={values?.height} addon="cm" />
          </Form.Item>
        </Col>
        <Col sm={12} md={6} xs={12}>
          <Form.Item name={[name, 'volume']} {...restField}>
            <FloatNumber
              label="Volume"
              value={values?.volume}
              result={getVolume}
              addon="m³"
              disable
            />
          </Form.Item>
        </Col>

        {values?.innerPackaging && (
          <>
            <Col sm={24} md={24} xs={24}>
              <Typography.Title level={5} className={styles.title}>
                Inner packaging
              </Typography.Title>
            </Col>

            <Col sm={24} md={6} xs={24}>
              <Form.Item name={[name, 'innerPackagingQuantity']} {...restField}>
                <FloatNumber
                  label="Number of inn. pack.."
                  value={values?.innerPackagingQuantity}
                  controls
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} xs={24}>
              <Form.Item name={[name, 'innerPackagingType']} {...restField}>
                <Select
                  value={values?.innerPackagingType}
                  label="Inner packaging"
                  search
                  options={
                    innerPackage?.map((item) => ({
                      value: item.innerPackageMaterialId,
                      label: item.name,
                    })) || []
                  }
                />
              </Form.Item>
            </Col>

            <Col sm={24} md={6} xs={24}>
              <Form.Item
                name={[name, 'innerPackagingNetUnitaryQuantity']}
                {...restField}
              >
                <FloatNumber
                  label="Net amount per pack."
                  value={values?.innerPackagingNetUnitaryQuantity}
                  addon="kg"
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col sm={24} md={12} xs={24}>
          <Row
            gutter={[
              {
                xs: 16,
                sm: 16,
                md: 16,
                lg: 24,
              },
              28,
            ]}
          >
            <Col sm={24} md={24} xs={24}>
              <Typography.Title level={5} className={styles.title}>
                Additional services
              </Typography.Title>
            </Col>

            <Col sm={24} md={24} xs={24}>
              <Form.Item
                valuePropName="checked"
                name={[name, 'temperatureLogger']}
                {...restField}
              >
                <Checkbox className="invisible">
                  <CustomCheckboxSwitch
                    title="Temperature Logger"
                    checked={values?.temperatureLogger}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
            <Col sm={24} md={24} xs={24}>
              <Form.Item
                valuePropName="checked"
                name={[name, 'realTimeMonitoring']}
                {...restField}
              >
                <Checkbox className="invisible">
                  <CustomCheckboxSwitch
                    title="Real Time Monitoring"
                    checked={values?.realTimeMonitoring}
                  />
                </Checkbox>
              </Form.Item>
            </Col>

            <Col sm={24} md={24} xs={24}>
              <Form.Item
                valuePropName="checked"
                name={[name, 'specialTemperatureMode']}
                {...restField}
              >
                <Checkbox className="invisible">
                  <CustomCheckboxSwitch
                    title="Special temperature  mode"
                    checked={values?.specialTemperatureMode}
                  />
                </Checkbox>
              </Form.Item>
            </Col>

            <Col sm={24} md={24} xs={24}>
              {values?.specialTemperatureMode && (
                <Form.Item name={[name, 'temperatureMode']} {...restField}>
                  <Select
                    value={values?.temperatureMode}
                    label="Temperature  mode"
                    options={
                      temperatureModes?.map((item) => ({
                        value: item,
                        label: item,
                      })) || []
                    }
                  />
                </Form.Item>
              )}
            </Col>
            <Col sm={24} md={24} xs={24}>
              {values?.temperatureMode
                === 'FROBO79 (Frozen Box UN 1845 -79 °C)' && (
                <Form.Item name={[name, 'netWeightOfDryIce']} {...restField}>
                  <FloatNumber
                    label="Net weight of Dry Ice"
                    value={values?.netWeightOfDryIce}
                    addon="kg"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={12} xs={24}>
          <Row
            gutter={[
              {
                xs: 16,
                sm: 16,
                md: 16,
                lg: 24,
              },
              28,
            ]}
          >
            <Col sm={24} md={24} xs={24}>
              <Typography.Title level={5} className={styles.title}>
                Export/Import permit
              </Typography.Title>
            </Col>

            <Col sm={24} md={24} xs={24}>
              <Form.Item
                name={[name, 'permit']}
                {...restField}
                rules={[{ required: true, message: 'Please specify a permit' }]}
              >
                <Select
                  value={values?.permit}
                  label="Permit"
                  required
                  options={[
                    { value: 'free', label: 'Free' },
                    { value: 'obligation', label: 'Obligation' },
                  ]}
                />
              </Form.Item>
            </Col>

            {values?.permit === 'obligation' ? (
              <>
                <Col sm={24} md={24} xs={24}>
                  <Form.Item
                    name={[name, 'permitNumber']}
                    rules={[
                      { required: true, message: 'Please enter a Permit No.' },
                    ]}
                  >
                    <Input required label="Permit No.:" />
                  </Form.Item>
                </Col>
                <Col sm={24} md={24} xs={24}>
                  <Date
                    message="Please select a Permit date"
                    label="Permit date"
                    index={name}
                    required
                    name="permitDate"
                    form={form}
                  />
                </Col>
              </>
            ) : null}

            <Col sm={24} md={24} xs={24}>
              {values?.dangerousGoods ? (
                <Upload
                  nestedName="goods"
                  index={name}
                  name="msdsDocument"
                  form={form}
                  label="Upload MSDS/SDS"
                />
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
