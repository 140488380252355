import { useState } from 'react';
import { Select as AntdSelect, FormInstance } from 'antd';
import clsx from 'clsx';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import floatStyles from '../index.module.scss';
import styles from './index.module.scss';

interface Places {
  country?: string;
  postalCode?: string;
  city?: string;
  state?: string;
}

interface SelectProps {
  label: string;
  type: string[];
  value?: string;
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  onSelect?: (value: string | undefined) => void;
  fields: {
    country: string;
    postalCode: string;
    city: string;
    state: string;
    address: string;
    countryCode: string;
    stateCode: string;
  };
  form: FormInstance;
  name: string;
}

function SelectPlace({
  label,
  value,
  required,
  onSelect,
  type,
  fields,
  form,
  name,
}: SelectProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || value;
  const values = form.getFieldsValue();

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: 'AIzaSyC9gv7c0h8fSS_eey9MGWZIX6OoLgaQvKA',
    options: { types: type },
  });

  return (
    <label
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AntdSelect
        variant="borderless"
        showSearch
        // @ts-ignore autoComplete="none"
        autoComplete="none"
        onSearch={(val) => {
          if (val.trim().length > 0) {
            form.setFieldValue(name, val);
            form.validateFields([name]);
          }

          const cityRegex = new RegExp(values[fields.city], 'i');
          const countryRegex = new RegExp(values[fields.country], 'i');

          if (name === 'address1') {
            if (values[fields.city] && !cityRegex.test(val)) {
              getPlacePredictions({ input: `${values[fields.city]} ${val}` });

              return;
            }
            if (values[fields.country] && !countryRegex.test(val)) {
              getPlacePredictions({
                input: `${values[fields.country]} ${val}`,
              });

              return;
            }
          }

          getPlacePredictions({ input: `${val}` });
        }}
        size="large"
        filterOption={false}
        value={value || undefined}
        className={clsx(floatStyles.input, styles.select)}
        onSelect={(val) => {
          if (onSelect) { onSelect(val); }

          const details: Places = {};
          const placeIndex = placePredictions.findIndex(
            (item) => item.description === val,
          );

          placesService?.getDetails(
            { placeId: placePredictions[placeIndex].place_id },
            (placeDetails: any) => {
              placeDetails.address_components.forEach((item: any) => {
                if (item.types.includes('country')) {
                  details[fields.country as keyof Places] = item.long_name;
                  details[fields.countryCode as keyof Places] = item.short_name;
                }
                if (item.types.includes('administrative_area_level_1')) {
                  details[fields.state as keyof Places] = item.long_name;
                  details[fields.stateCode as keyof Places] = item.short_name;
                }
                if (item.types.includes('locality')) {
                  details[fields.city as keyof Places] = item.long_name;
                }
                if (item.types.includes('postal_code')) {
                  details[fields.postalCode as keyof Places] = item.long_name;
                }
                if (item.types.includes('route')) {
                  details[fields.address as keyof Places] = item.long_name;
                }
                const route = placeDetails.address_components.find(
                  (place: any) => place.types.includes('route'),
                );
                const streat = placeDetails.address_components.find(
                  (place: any) => place.types.includes('street_number'),
                );

                if (route && streat) {
                  details[
                    fields.address as keyof Places
                  ] = `${route.long_name} ${streat.long_name}`;
                }

                form.setFieldsValue({ ...details });
                form.validateFields();
              });
            },
          );
        }}
        options={
          placePredictions.map((item) => ({
            value: item.description,
            label: item.description,
          })) || []
        }
      />
      <span
        className={clsx(
          floatStyles.label,
          isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder,
        )}
      >
        {label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </span>
    </label>
  );
}

SelectPlace.defaultProps = {
  value: '',
  required: false,
  onChange: () => {},
};

export default SelectPlace;
