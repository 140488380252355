import {
  Button, Checkbox, Col, Flex, Form, Input as AntdInput, Row, Space, Typography,
} from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect } from 'react';
import { preferredDeliveryDefaultValue, useContextForm } from '../../Context';
import {
  pickupFields, shipperFields, getIcon, importerFields, deliveryFields,
} from './helper';
import CustomCheckboxSwitch from '../../Components/CustomCheckbox';
import styles from './index.module.scss';
import {
  FileType, Importer, PreferredDeliveryInfo, Shipper, useHSCodeGetAll,
} from '../../../../../hooks/api/order';
import File from './File';
import SelectCountry from '../../../../Common/Selects/SelectCountry';
import Input from '../../Components/Float/Input';
import SelectDeliveryService from '../../../../Common/Selects/SelectDeliveryService';
import SelectBillingCodeType from '../../../../Common/Selects/SelectBillingCodeType';

export default function Overview(): React.ReactNode | null {
  const {
    formData, setCurrent, current, createOrder, loading, setNotes, handlePreferredDelivery,
    packages, innerPackage, preferredDeliveryInfo, notes,
  } = useContextForm();
  const hsCodeGetAll = useHSCodeGetAll();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const preferredDelivery = Form.useWatch('preferredDeliveryService', form);
  const billingAccNumberWatch = Form.useWatch('billingNumber', form) || '';

  useEffect(() => {
    form.setFieldValue('notes', notes);
    const mapFields: (keyof PreferredDeliveryInfo)[] = ['preferredDeliveryService', 'billingNumber',
      'billingNumberCountryCode', 'billingCode', 'billingType'];

    mapFields.forEach((key) => {
      form.setFieldValue(key, preferredDeliveryInfo?.[key]);
    });
  }, [notes, preferredDeliveryInfo]);

  useEffect(() => {
    hsCodeGetAll.fetch();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Typography.Title level={2}>Order overview</Typography.Title>
      <div className={styles.container}>
        <div className={styles.box}>
          <Flex justify="space-between" align="center" className={styles.header}>
            <Typography.Title level={3}>Shipper</Typography.Title>
            <Button onClick={() => setCurrent(0)}>
              EDIT
            </Button>
          </Flex>
          <Row className={styles.row} gutter={[{ xs: 12 }, 12]}>
            <Col sm={8} xs={24} md={8} style={{ paddingLeft: 0 }}>
              <Flex>
                <div className={styles.icon}>
                  {formData?.shipper?.shippingType && getIcon(formData?.shipper?.shippingType)}
                </div>
                <Space.Compact direction="vertical" className={styles.list}>
                  <Typography.Text className={styles.listTitle}>
                    Shipping type
                  </Typography.Text>
                  <Typography.Text className={styles.capitalize}>
                    {formData?.shipper?.shippingType}
                  </Typography.Text>
                </Space.Compact>
              </Flex>
              {formData?.shipper?.pickupDateTo && (
                <Flex className={styles.listItem}>
                  <div className={styles.icon}>
                    <CalendarOutlined />
                  </div>
                  <Space.Compact direction="vertical" className={styles.list}>
                    <Typography.Text className={styles.listTitle}>
                      Pick-up date & time
                    </Typography.Text>
                    <Typography.Text>
                      {dayjs(formData?.shipper?.pickupDateTo).utc().format('DD/MM/YYYY')}
                    </Typography.Text>
                    <Typography.Text>
                      {`${dayjs(formData?.shipper?.pickupDateFrom).utc().format('HH:mm')}
                    -  ${dayjs(formData?.shipper?.pickupDateTo).utc().format('HH:mm')}`}
                    </Typography.Text>
                  </Space.Compact>
                </Flex>
              )}
            </Col>
            <Col sm={8} xs={24} md={8}>
              <Space.Compact direction="vertical">
                <Typography.Text strong> Shipper details </Typography.Text>
                {shipperFields.map((name) => (
                  <Typography.Text key={`${name}`}>
                    {formData?.shipper?.[name as keyof Shipper]}
                  </Typography.Text>
                ))}
              </Space.Compact>
            </Col>
            <Col sm={8} xs={24} md={8}>
              <Space.Compact direction="vertical" size="small">
                <Typography.Text strong>Pick-up details </Typography.Text>
                {
                  formData?.shipper?.pickupContactName ? (
                    pickupFields.map((name) => (
                      <Typography.Text key={`${name}`}>
                        {formData?.shipper?.[name as keyof Shipper]}
                      </Typography.Text>
                    ))
                  ) : (
                    shipperFields.map((name) => (
                      <Typography.Text key={`${name}`}>
                        {formData?.shipper?.[name as keyof Shipper]}
                      </Typography.Text>
                    ))
                  )
                }
              </Space.Compact>
            </Col>
          </Row>
        </div>
        <div className={styles.box}>
          <Flex justify="space-between" align="center" className={styles.header}>
            <Typography.Title level={3}>Importer</Typography.Title>
            <Button onClick={() => setCurrent(1)}>
              EDIT
            </Button>
          </Flex>
          <Row className={styles.row} gutter={[{ xs: 12 }, 12]}>
            <Col sm={8} xs={24} md={8} />
            <Col sm={8} xs={24} md={8}>
              <Space.Compact direction="vertical">
                <Typography.Text strong>Importer details</Typography.Text>
                {importerFields.map((name) => (
                  <Typography.Text key={`${name}`}>
                    {formData?.importer?.[name as keyof Importer]}
                  </Typography.Text>
                ))}
              </Space.Compact>
            </Col>
            <Col sm={8} xs={24} md={8}>
              <Space.Compact direction="vertical" size="small">
                <Typography.Text strong>Delivery details </Typography.Text>
                {
                  formData?.importer?.deliveryContactName ? (
                    deliveryFields.map((name) => (
                      <Typography.Text key={`${name}`}>
                        {formData?.importer?.[name as keyof Importer]}
                      </Typography.Text>
                    ))
                  ) : (
                    importerFields.map((name) => (
                      <Typography.Text key={`${name}`}>
                        {formData?.importer?.[name as keyof Importer]}
                      </Typography.Text>
                    ))
                  )
                }
              </Space.Compact>
            </Col>
          </Row>
        </div>
        <div className={styles.box}>
          <Flex justify="space-between" align="center" className={styles.header}>
            <Typography.Title level={3}>List of goods</Typography.Title>
            <Button onClick={() => setCurrent(2)}>
              EDIT
            </Button>
          </Flex>
          {formData?.goods?.map((item) => (
            <div key={item.description} className={clsx(styles.row, styles.goodsRow)}>
              <Row>
                <Col sm={2} xs={5} md={2}>
                  <Typography.Text className={styles.qty}>{item?.quantity && `${item?.quantity}x`}</Typography.Text>
                </Col>
                <Col sm={6} xs={19} md={6}>
                  <Typography.Text>
                    {
                      packages?.find((packag) => packag.packageId === item?.packaging)?.name || ''
                    }
                  </Typography.Text>
                </Col>
                <Col sm={0} xs={5} md={0} />
                <Col sm={8} xs={19} md={8}>
                  <Space.Compact direction="vertical">
                    {item?.unNumber && <Typography.Text>{item?.unNumber}</Typography.Text>}
                    {item?.hsCode
                     && (
                     <Typography.Text>
                       {hsCodeGetAll?.data?.find((code) => item?.hsCode === code.value)?.label}
                     </Typography.Text>
                     )}
                    <Typography.Text>{item.description}</Typography.Text>

                    {item?.innerPackaging && (
                      <Typography.Text className={styles.listItem}>
                        {item?.innerPackagingQuantity
                          ? `${item.innerPackagingQuantity} x `
                          : null}

                        {item?.innerPackagingType
                          ? innerPackage?.find((type) => type.innerPackageMaterialId === item?.innerPackagingType)?.name
                          : null}

                        {item?.innerPackagingNetUnitaryQuantity
                          ? `, ${item?.innerPackagingNetUnitaryQuantity} kg`
                          : null}
                      </Typography.Text>
                    )}
                  </Space.Compact>
                </Col>
                <Col sm={0} xs={5} md={0} />
                <Col sm={8} xs={19} md={8}>
                  <Space.Compact direction="vertical">
                    {item.value && (
                      <Typography.Text>
                        {`${item.value * (item?.quantity || 1)} ${item.currency || 'CHF'}`}
                      </Typography.Text>
                    )}
                    {item.net && (
                      <Typography.Text>
                        {`Net ${Number.parseFloat((item.net * (item?.quantity || 1))
                          .toFixed(3))} ${item.massUnit || 'kg'}`}
                      </Typography.Text>
                    )}
                    {item.gross && (
                      <Typography.Text>
                        {`Gross ${item.gross * (item?.quantity || 1)} kg`}
                      </Typography.Text>
                    )}
                    {item?.length && item.width && item.height && (
                      <Typography.Text>
                        {`L${item?.length} x W${item.width} x H${item.height} cm`}
                      </Typography.Text>
                    )}

                    {item?.length && item.width && item.height && (
                      <Typography.Text>
                        {`${(((item.height * item.length * item.width)
                           / 1000000) * (item?.quantity || 1)).toFixed(3)} m3`}
                      </Typography.Text>
                    )}

                  </Space.Compact>
                </Col>
              </Row>

              <Row>
                <Col sm={8} xs={5} md={8} />
                <Col className={styles.rowBottom} sm={16} xs={19} md={16}>
                  <Space.Compact direction="vertical">
                    {item.permit && (
                      <Typography.Text>
                        {`Permit: ${item.permit}`}
                      </Typography.Text>
                    )}
                    {item.permit === 'obligation' && (
                      <>
                        <Typography.Text>
                          {`Permit No.: ${item.permitNumber}`}
                        </Typography.Text>
                        <Typography.Text>
                          {`Permit date: ${dayjs(item.permitDate).utc().format('DD/MM/YYYY')}`}
                        </Typography.Text>
                      </>
                    )}

                    {item.exportLicenseDocument && <File name={(item.exportLicenseDocument as FileType).name} />}
                    {item.msdsDocument && <File name={(item.msdsDocument as FileType).name} />}

                    {item.temperatureMode && (
                      <Typography.Text>
                        {`Special temperature mode: ${item?.temperatureMode}`}
                        {item?.temperatureMode === 'FROBO79 (Frozen Box UN 1845 -79 °C)' && item?.netWeightOfDryIce && (
                          `, ${item?.netWeightOfDryIce} kg`
                        )}
                      </Typography.Text>
                    )}
                    {item.temperatureLogger && (<Typography.Text>Temperature Logger: Yes</Typography.Text>)}
                    {item.realTimeMonitoring && (<Typography.Text>Real Time Monitoring: Yes</Typography.Text>)}
                  </Space.Compact>
                </Col>
              </Row>
            </div>
          ))}
        </div>
        <div className={styles.box}>
          <Flex justify="space-between" align="center" className={styles.header}>
            <Typography.Title level={3}>Shipping info</Typography.Title>
            <Button onClick={() => setCurrent(2)}>
              EDIT
            </Button>
          </Flex>
          <Row className={styles.row}>
            <Col sm={8} xs={24} md={8} />
            <Col sm={8} xs={24} md={8}>
              <Space.Compact direction="vertical">
                {formData?.deliveryTerms && (
                  <Typography.Text>{`Delivery Tems ${formData.deliveryTerms}`}</Typography.Text>
                )}
                {formData?.insurance && (
                <Typography.Text>
                  {`Transport Insurance: ${formData?.insuranceCurrency} ${formData?.insuranceValue || 0}`}
                </Typography.Text>
                )}
              </Space.Compact>
            </Col>
            <Col sm={8} xs={24} md={8}>
              {formData?.proformaInvoice && <File name={(formData.proformaInvoice as FileType)?.name} />}
              {/* @ts-ignore FileType error */}
              {formData?.additionalDocuments?.filter((item) => (item?.file as FileType)?.id)
                // @ts-ignore FileType error
                ?.map((item) => <File key={(item.file as FileType)?.id} name={((item.file as FileType))?.name} />)}
            </Col>
          </Row>
        </div>
      </div>

      <Form
        form={form}
        autoComplete="off"
        className={styles.form}
      >
        <Typography.Paragraph className={styles.notesTitle}>
          Shipping bill to consignee or third party?
        </Typography.Paragraph>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectDeliveryService
              optionsType="preferredDelivery"
              label="Preferred delivery service"
              name="preferredDeliveryService"
              rules={[{ required: false, message: <div /> }]}
              selectProps={{
                onChange: (val) => {
                  ['billingNumberCountryCode', 'billingCode', 'billingType'].forEach((fieldName) => {
                    form.setFieldValue(fieldName, null);
                    handlePreferredDelivery({ [fieldName]: undefined });
                  });
                  handlePreferredDelivery({ preferredDeliveryService: val });
                },
              }}
            />

          </Col>
          <Col span={12}>
            <Form.Item name="billingNumber">
              <Input
                label="Billing account number"
                onChange={(e) => handlePreferredDelivery({ billingNumber: e.target.value })}
              />
            </Form.Item>
          </Col>

          {(preferredDelivery === 'fedex' && billingAccNumberWatch?.length >= 2) ? (
            <>
              <Col span={12}>
                <SelectCountry
                  label="Billing account country"
                  name="billingNumberCountryCode"
                  valueKey="code"
                  onChange={(val) => handlePreferredDelivery({ billingNumberCountryCode: val })}
                />
              </Col>
              <Col span={12}>
                <SelectBillingCodeType
                  fetchPath="billing-codes"
                  label="Billing code"
                  name="billingCode"
                  onChange={(val) => handlePreferredDelivery({ billingCode: val })}
                />
              </Col>
              <Col span={12}>
                <SelectBillingCodeType
                  fetchPath="billing-types"
                  label="Billing type"
                  name="billingType"
                  onChange={(val) => handlePreferredDelivery({ billingType: val })}
                />
              </Col>
            </>
          ) : null}
        </Row>

        <Typography.Paragraph className={styles.notesTitle}>Important notes for Sephyre</Typography.Paragraph>

        <Form.Item name="notes">
          <AntdInput.TextArea className={styles.textarea} rows={5} placeholder="Please enter" />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="terms"
          // eslint-disable-next-line max-len
          rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please confirm that you are read and agree with Sephyre Privacy Policy and Terms & Conditions.'))) }]}
        >
          <Checkbox className="invisible">
            <CustomCheckboxSwitch
              title={(
                <div>
                  Please confirm that you are read and agree with &nbsp;
                  <a href="https://www.sephyre.com/data-privacy/" target="_blank" rel="noreferrer">
                    Sephyre Privacy Policy
                  </a>
                  &nbsp; and &nbsp;
                  <a href="https://www.sephyre.com/terms-conditions/" target="_blank" rel="noreferrer">
                    Terms & Conditions
                  </a>
                </div>
              )}
              checked={values?.terms}
            />
          </Checkbox>
        </Form.Item>
      </Form>
      <Flex justify="flex-end">
        <Button
          className={styles.btn}
          size="large"
          loading={loading}
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then(() => {
                setNotes(form.getFieldValue('notes'));
                // const fieldsKeys = ['preferredDeliveryService', 'billingNumber', 'billingNumberCountryCode',
                //   'billingCode', 'billingType'];
                // const newValues = fieldsKeys.reduce((acc, key) => {
                //   acc[key] = form.getFieldValue(key);
                //
                //   return acc;
                // }, {} as Record<string, any>);
                //
                // handlePreferredDelivery(newValues);
                createOrder().then((res) => {
                  if (res) {
                    setCurrent(current + 1);
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          PLACE YOUR ORDER
        </Button>
      </Flex>
    </div>
  );
}
