import { Form, Radio } from 'antd';
import styles from './index.module.scss';
import {
  AirIcon, CourierIcon, RailIcon, SeaIcon, TruckIcon,
} from '../../../../Common/Icon';

export default function RadioBtns(): React.ReactNode | null {
  return (
    <Form.Item
      name="shippingType"
      rules={[{ required: true }]}
    >
      <Radio.Group className={styles.radio}>
        <Radio.Button value="Courier">
          <div className={styles.radioWrap}>
            <CourierIcon />
            Courier
          </div>
        </Radio.Button>
        <Radio.Button value="Road Freight (ADR)">
          <div className={styles.radioWrap}>
            <TruckIcon />
            Truck
          </div>
        </Radio.Button>
        <Radio.Button value="Air Freight (IATA)">
          <div className={styles.radioWrap}>
            <AirIcon />
            Air
          </div>
        </Radio.Button>
        <Radio.Button value="Sea Freight (IMDG-IMO)">
          <div className={styles.radioWrap}>
            <SeaIcon />
            Sea
          </div>
        </Radio.Button>
        <Radio.Button value="Rail">
          <div className={styles.radioWrap}>
            <RailIcon />
            Rail
          </div>
        </Radio.Button>

      </Radio.Group>
    </Form.Item>
  );
}
