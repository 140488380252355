import { Steps } from 'antd';
import styles from './index.module.scss';
import Shipping from '../Steps/Shipping';
import { useContextForm } from '../Context';
import Importer from '../Steps/Importer';
import Goods from '../Steps/Goods';
import Overview from '../Steps/Overview';
import Confirmation from '../Components/Confirmation';

export default function Wrapper(): React.ReactNode | null {
  const { current, setCurrent } = useContextForm();
  const stepNames = ['shipper', 'importer', 'goods'];

  const steps = [
    {
      title: 'Shipper',
      content: <Shipping />,
    },
    {
      title: 'Importer',
      content: <Importer />,
    },
    {
      title: 'Goods',
      content: <Goods />,
    },
    {
      title: 'Overview',
      content: <Overview />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className={styles.wrapper}>
      {current < 4 && (
      <div className={styles.stepsWrap}>
        <Steps
          onChange={(step) => {
            // console.log(formData?.[stepNames?.[step]]);
            if (step < current) {
              setCurrent(step);
            }
          }}
          current={current}
          items={items}
          progressDot
          className={styles.steps}
          responsive={false}
        />
      </div>
      )}
      {current < 4 ? <div className={styles.content}>{steps[current].content}</div> : <Confirmation />}
    </div>
  );
}
