import React from 'react';
import { Layout as AntdLayout } from 'antd';

import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}

export default function Simple({ children }: SimpleProps): React.ReactNode {
  return (
    <AntdLayout>
      <AntdLayout.Content className={styles.content}>
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  );
}
